import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {BgImage} from 'gbimage-bridge';
import ContactForm from '../components/form';

import Layout from '../components/layout';
import Seo from '../components/seo';

const ContactPage = () => {
    const data = useStaticQuery(graphql `
    query {
        contactImage: file(relativePath: {eq: "contact-page.jpg"}) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }           
  `);

    const bgImg = data.contactImage.childImageSharp.gatsbyImageData;

    return (
        <Layout>
            <Seo title="Contact"/>
            <section id="contact-us">
                <BgImage
                    image={bgImg}
                    className="flex flex-col mx-auto h-full justify-center items-center pt-topMobile lg:pt-top"
                    style={{
                    minWidth: "100%",
                    minHeight: "100%"
                }}>
                    <div className="lg:p-10 w-full">
                        <div className="lg:container lg:mx-auto bg-darkRed bg-opacity-90 shadow-lg">
                            <ContactForm/>
                        </div>
                    </div>
                </BgImage>
            </section>
        </Layout>
    )
};

export default ContactPage;
