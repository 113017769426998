import React, { useState } from "react"
import { FiPhoneCall } from "react-icons/fi"
import { AiOutlineMail, AiOutlineHome } from "react-icons/ai"

const onSubmit = async (event, setSubmitText) => {
  event.preventDefault()
  setSubmitText("Submitting ...")
  const formElements = [...event.currentTarget.elements]
  const isValid =
    formElements.filter(elem => elem.name === "bot-field")[0].value === ""

  const validFormElements = isValid ? formElements : []

  if (validFormElements.length < 1) {
    // or some other cheeky error message
    setSubmitText("It looks like you filled out too many fields!")
  } else {
    const filledOutElements = validFormElements
      .filter(elem => !!elem.value)
      .map(
        element =>
          encodeURIComponent(element.name) +
          "=" +
          encodeURIComponent(element.value)
      )
      .join("&")

    await fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: filledOutElements,
    })
      .then(() => {
        setSubmitText("Successfully submitted!")
      })
      .catch(_ => {
        setSubmitText(
          "There was an error with your submission, please email us using this address: info@helmdiamonddrilling.ca"
        )
      })
  }
}

const ContactForm = () => {
  const [submitText, setSubmitText] = useState(null)
  return (
    <div id="contact-form" className="grid px-10 py-6 text-white">
      <h1 className="my-4 halogen uppercase">Get in Touch</h1>
      <div className="container flex mb-8">
        <div className="w-full">
          {submitText && (
            <div>
              <p className="halogen text-xl text-center text-white py-48">
                {submitText}
              </p>
              <div className="bg-black flex flex-col shadow-xl p-10">
                <p className="flex">
                  <AiOutlineHome className="mr-2 text-lg" />
                  Prince George, B.C
                </p>
                <p className="flex mt-5 items-center">
                  <AiOutlineMail className="mr-2 text-lg" />
                  <a href="mailto:info@helmdiamonddrilling.ca">
                    info@helmdiamonddrilling.ca
                  </a>
                </p>
                <p className="flex items-center">
                  <FiPhoneCall className="mr-2 text-lg" />
                  (250) 552-4224
                </p>
              </div>
            </div>
          )}
          {submitText ? null : (
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={e => onSubmit(e, setSubmitText)}
            >
              <p
                style={{
                  display: "none",
                }}
              >
                <label>
                  Don’t fill this out if you expect to hear back!
                  <input name="bot-field" value="" readOnly />
                </label>
              </p>
              <input type="hidden" name="form-name" value="contact" />
              <div className="mb-4">
                <label htmlFor="name" className="block mb-2">
                  Your Name (required)
                </label>
                <input
                  required={true}
                  className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                  type="text"
                  name="name"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block mb-2">
                  Your Email (required)
                </label>
                <input
                  required={true}
                  className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-16 p-3 text-gray-700"
                  type="email"
                  name="email"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="message" className="block mb-2">
                  Your Message
                </label>
                <textarea
                  required={true}
                  className="form-control bg-grey border-b-2 outline-none border-gray-600 w-full h-44 p-3 text-gray-700"
                  name="message"
                />
              </div>
              <button type="submit" aria-label="button" className="btn-md mb-6">
                Submit
              </button>
              <div className="bg-black flex flex-col shadow-xl p-10">
                <p className="flex">
                  <AiOutlineHome className="mr-2 text-lg" />
                  Prince George, B.C
                </p>
                <p className="flex mt-5 items-center">
                  <AiOutlineMail className="mr-2 text-lg" />
                  <a href="mailto:info@helmdiamonddrilling.ca">
                    info@helmdiamonddrilling.ca
                  </a>
                </p>
                <p className="flex items-center">
                  <FiPhoneCall className="mr-2 text-lg" />
                  (250) 552-4224
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default ContactForm
